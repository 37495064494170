// Inserts a transformation into a cloudinary url
module.exports.withTransformation = (url, transformation) => {
  if (url) {
    let splitStr = url.split('upload/')
    if (transformation) return splitStr.join(`upload/${transformation}/`)
    else return url
  }
}

module.exports.getAlgoliaIndex = () => {
  if (process.env.OXYGEN === 'local' || process.env.ALGOLIA_INDEX === 'local') return 'local_typenexmedical'
  if (process.env.ALGOLIA_INDEX === 'production') return 'prod_and_test_typenexmedical'
  else return 'dev_typenexmedical'
}